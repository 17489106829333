$primary: #009883;
$secondary: #131313;
$grey-05: #f6f6f6;
$grey: #6e6568;
$yellow: #f8c045;
$orange: #f07a39;
$black: #222222;
$success-05: #f5ffeb;
$success: #76bd3e;
$info-05: #e9f7ff;
$info: #4da0ff;
$danger-05: #ffebeb;
$danger: #e46161;
$white: #ffffff;
$white-05: #d8d8d8;
$font-primary: 'Manrope';
$font-secondary: 'Quicksand';
$dark-blue: #42b7c3;
$dark-green: #21af7d;
